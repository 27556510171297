import React from "react";
import { css } from "@emotion/core";
import { useStaticQuery, Link, graphql } from "gatsby";
import { rhythm } from "../utils/typography";


export default ({ children }) => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    )
    return (
        <div className="outer">
            <nav>
                <Link to={`/`}>
                    <h4
                        css={css`
                margin-bottom: ${rhythm(2)};
                display: inline-block;
                font-style: normal;
                `}
                    >
                        {data.site.siteMetadata.title}
                    </h4>
                </Link>
                <Link
                    to="/about/"
                    css={css`
                float: right;
                margin-right: .5rem;
            `}
                >Om bloggen
        </Link>
                <Link
                    to="/tags/"
                    css={css`
                float: right;
                margin-right: .5rem;
            `}
                >Ämnen
        </Link>
                <Link
                    to="/blog/"
                    css={css`
                float: right;
                margin-right: .5rem;
            `}
                >Alla
        </Link>
            </nav>
            <div className="wrapper">
                {children}
            </div>
        </div>
    )
}