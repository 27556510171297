import Typography from "typography";
import twinPeaks from "typography-theme-twin-peaks";

twinPeaks.overrideThemeStyles = ({ rhythm }, options) => ({
    a: { color: '#965800' }
})

const typography = new Typography(twinPeaks);

export default typography;
export const rhythm = typography.rhythm;